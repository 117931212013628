import React from "react";
import { Button, Layout, Row, Col } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { AiOutlineFilePdf, AiOutlineContacts } from "react-icons/ai";
import CodigoEtica from "../../assets/documents/Codigo de Etica.pdf";
import DeclaracionJurada from "../../assets/documents/Declaracion Jurada.pdf";
import Decreto20217 from "../../assets/documents/Decreto 202-17.pdf";
import FormFisicas from "../../assets/documents/Formulario Decreto 202 - Físicas.pdf";
import FormJuridicas from "../../assets/documents/Formulario Decreto 202 - Jurídicas.pdf";
import Instructivo from "../../assets/documents/Instructivo.pdf";
import Procedimiento from "../../assets/documents/Procedimiento Compras y Contrataciones.pdf"

const subHeaderStyle = {
  textAlign: "left",
  height: 32,
  paddingInline: 20,
  lineHeight: "32px",
  color: "#ffffff",
  backgroundColor: "#0f77a4",
};

const documentListStyle = {
  height: 35,
  textAlign: "left",
  backgroundColor: "#14a0db",
  margin: "5px",
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  color: "white"
};

const formLinkStyle = {
  height: "100px",
  textAlign: "center",
  padding: "15px",
  backgroundColor: "#14a0db",
  margin: "5px",
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const textStyle = {
  margin: "auto",
  maxWidth: "900px",
  display: "flex",
  padding: "25px 10px 30px",
  textAlign: "left",
  color: "black",
  overflow: "auto",
  backgroundColor: "#ffffff",
  overflowY: "auto",
  flexDirection: "column",
};

const colStyle = {
  flex: "1",
};

export const LandingPage = () => {
  return (
    <div>
      <Layout style={subHeaderStyle}>Home</Layout>
      <Layout style={textStyle}>
  
        <b>Bienvenidos al portal de proveedores de Termoeléctrica José de San Martin SA, a continuación encontrará el instructivo, formularios y requisitos para generar el alta como proveedor en nuestra empresa.</b>
        <br/>
         
        <br/>
        <div>• <b>INSTRUCTIVO   </b><a href={Instructivo} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
        <br/>
         
        <br/>
        <div>• <b>Documentación requerida:</b></div>
        <br/>
         
        <br/>
            • Legajo impositivo (Inscripción AFIP e IIBB – Exenciones y/o Certificados de No Retención.)
        <br/>
            • Datos Bancarios (En hoja membretada – Incluir Nro de CUIT).
        <br/>
        <div>    • Declaración Jurada Proveedor <a href={DeclaracionJurada} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
        <br/>
        <div>    • Formulario Decreto 202/2017 firmado (Persona Física o Jurídica, según corresponda):</div>
         
        <br/>
        <div>            Persona Física <a href={FormFisicas} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
         
        <br/>
        <div>            Persona Jurídica <a href={FormJuridicas} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
         
        <br/>
        <div>• <b>DECRETO 202/2017   </b><a href={Decreto20217} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
        <br/>
         
        <br/>
        <div>• <b>CODIGO DE ÉTICA   </b><a href={CodigoEtica} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
        <br/>
         
        <br/>
        <div>• <b>PROCEDIMIENTO DE COMPRAS Y CONTRATACIONES   </b><a href={Procedimiento} target="_blank"><Button type="primary" style={{backgroundColor: "#14a0db"}} shape="round" icon={<DownloadOutlined />} size={'small'}>Descargar</Button></a></div>
        
      </Layout>
      <Row style={{ display: "flex", maxWidth: "900px", margin: "auto" }}>
        {/* <Col style={{ ...colStyle, flex: 6 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href={CodigoEtica} target="_blank" style={{textDecoration: "none"}}>
              <Row style={documentListStyle}>
                <AiOutlineFilePdf
                  style={{ color: "red", fontSize: "25px", marginRight: "5px" }}
                />{" "}
                <b>Código de Ética</b>
              </Row>
            </a>
            <a href={DeclaracionJurada} target="_blank" style={{textDecoration: "none"}}>
              <Row style={documentListStyle}>
                <AiOutlineFilePdf
                  style={{ color: "red", fontSize: "25px", marginRight: "5px" }}
                />{" "}
                Declaración Jurada
              </Row>
            </a>
            <a href={Decreto20217} target="_blank" style={{textDecoration: "none"}}>
              <Row style={documentListStyle}>
                <AiOutlineFilePdf
                  style={{ color: "red", fontSize: "25px", marginRight: "5px" }}
                />{" "}
                Decreto 202-17
              </Row>
            </a>
            <a href={FormFisicas} target="_blank" style={{textDecoration: "none"}}>
              <Row style={documentListStyle}>
                <AiOutlineFilePdf
                  style={{ color: "red", fontSize: "25px", marginRight: "5px" }}
                />{" "}
                Formulario Decreto 202 - Físicas
              </Row>
            </a>
            <a href={FormJuridicas} target="_blank" style={{textDecoration: "none"}}>
              <Row style={documentListStyle}>
                <AiOutlineFilePdf
                  style={{ color: "red", fontSize: "25px", marginRight: "5px" }}
                />{" "}
                Formulario Decreto 202 - Jurídicas
              </Row>
            </a>
          </div>
        </Col> */}
        <Col style={{ ...colStyle, flex: 3 }}>
          <a href="/form" style={{ textDecoration: "none", color: "white" }}>
            <div style={formLinkStyle}>
              REGISTRO DE PROVEEDORES
              <AiOutlineContacts
                style={{ fontSize: "75px", marginRight: "5px" }}
              />{" "}
            </div>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
